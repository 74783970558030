/* 
------------------------------------------
   ------------------------------------------

Template Name: " "
Template URI: http://webcode4u.com
Description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit..."
Author: Al Amin Sobuj
Author URI: http://webcode4u.com
Version: 1.0

------------------------------------------
   ------------------------------------------
@File: Tamplate Styles

* This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.

This files contents are outlined below>>>>>

------------------------------------------
   ------------------------------------------

** - INDEX PAGE CSS

** - Default CSS
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
** - 
------------------------------------------
   ------------------------------------------
*/

@import url("https://fonts.googleapis.com/css?family=Roboto:300,500,700,900&display=swap");

* {
  padding: 0;
  margin: 0;
  align-content: center;
}
a,
a:active,
a:focus,
a:hover {
  outline: none;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 15px;
  font-weight: 700;
}

p {
  margin: 0 0 15px;
  font-weight: 300;
}
p:last-child {
  margin-bottom: 0;
}
img {
  max-width: 100%;
  height: auto;
}
body {
  color: #231f20;
  font-size: 16px;
  max-width: 100%;
  font-family: "Roboto", sans-serif;
}
.container {
  max-width: 1170px;
}
/*
  Default Form
=================*/
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="submit"],
input[type="search"],
button[type="submit"],
textarea {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  box-shadow: none;
  padding: 12px 15px;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  width: 100%;
  min-height: 45px;
  margin-bottom: 15px;
  outline: none;
}
input[type="submit"],
button[type="submit"] {
  cursor: pointer;
}

input:focus,
textarea:focus {
  outline: none;
  border: 1px solid #6fbc29;
}

textarea {
  min-height: 170px;
}

input::-moz-placeholder {
  color: #262626;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder {
  color: #262626;
}

input::-webkit-input-placeholder {
  color: #262626;
}

.spacing {
  padding: 80px 0;
}

.box-shadow {
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5) !important;
}

/*Button*/
.wt-btn a {
  display: inline-block;
  padding: 10px 25px;
  color: #fff;
  background: #369e90;
  border-radius: 50px;
}

.wt-btn a:hover {
  background: #28beef;
}

header {
  text-align: center;
}

/*Header Top*/
.header-top {
  background: #fff;
  padding: 15px 0;
}

.header-top__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-top__left {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 200px;
}

.header-top__right {
  display: inline-flex;
  align-items: center;
}

.search-btn a {
  display: inline-flex;
  width: 40px;
  height: 40px;
  background: #000;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #fff;
  font-size: 20px;
}

.search-btn {
  margin-left: 10px;
}

.user-profile__icon {
  margin-right: 10px;
}

select.select_menu {
  border-width: 0;
  color: #818284;
  cursor: pointer;
  background-color: #fff;
  border: none;
}

select.select_menu:focus {
  outline: none;
}

.user-profile__icon a {
  color: #818284;
}

/*Secondary Menu*/
.secondary-menu {
  margin-bottom: 0;
  display: flex;
  align-items: center;
  list-style: none;
  margin-top: 10px;
}

.secondary-menu li {
  margin-left: 10px;
  margin-right: 10px;
}

.secondary-menu li a {
  display: block;
  color: #818284;

  font-size: 14px;
}

.secondary-menu li:hover > a,
.secondary-menu li.active a,
.main_menu ul li.active a,
.main_menu ul li:hover > a {
  color: #369e90;
}

.header-bottom {
  background: #f7f7f8;
}

.main_menu ul li a {
  color: #000002;
  font-weight: 700;
}

.main_menu > ul > li:first-child a {
  padding-left: 0;
}

/*Home Hero*/

.home_hero {
  background-color: #369e90;
  background-position: center;
  background-size: cover;
  background-image: url("assets/images/hero-bg.jpg");
  padding: 100px 0 140px 0;
}
.home_hero--only {
  padding: 120px 0;
}

.home-hero__content,
.home-hero__content a {
  color: #fff;
}

.home-hero__content a {
  text-decoration: underline;
}

.home-hero__content h1 {
  font-size: 45px;
}

.ellipse {
  position: relative;
  z-index: 8;
}

.ellipse_shape {
  content: "";
  width: 40%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.ellipse_shape:before,
.ellipse_shape:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #369e90;
  z-index: -1;
}

.ellipse_shape:after {
  width: 250px;
  background: #369e90;
  left: 100%;
  z-index: -1;
  clip-path: ellipse(50% 55% at 0% 50%);
}

.search-filter-wrap form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.search-filter-wrap form h3,
.search-filter-wrap form p {
  line-height: 1;
  margin-bottom: 0;
}

.search-filter-wrap select.select_menu {
  font-size: 30px;
  font-weight: 700;
  color: #000;
}

/* .search-filter-wrap form div:not(:last-child):after {
  content: "";
  height: 100%;
  width: 1px;
  background: #d8d9db;
  position: absolute;
  top: 0;
  right: -45px;
} */

.search-filter-wrap {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin-top: -65px;
  position: relative;
  z-index: 9;
  background: #fff;
  border-radius: 5px;
}

.search-filter-wrap > form > div {
  padding-top: 15px;
  padding-bottom: 15px;
  position: relative;
}

button[type="submit"] {
}

.search-filter-wrap button[type="submit"] {
  border-radius: 50px;
  min-width: 150px;
  margin-bottom: 0;
  background-color: #28beef;
  border-color: #28beef;
  color: #fff;
}

.search-filter-wrap button[type="submit"]:hover {
  background-color: #369e90;
  border-color: #369e90;
}

.search-filter-wrap form h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 45px;
}

select {
  padding-right: 15px;
  background-size: 10px;
  background-repeat: no-repeat;
  background-position: right center;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(assets/images/down-arrow-of-angle.svg);
}

.product-box {
  border-radius: 3px;
  position: relative;
}

.badge {
  position: absolute;
  height: 60px !important;
  width: 60px !important;
  background: rgba(40, 190, 239, 0.9);
  border-radius: 50px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  right: 15px;
  top: 15px;
  color: #fff;
}

.product-box__bg {
  min-height: 225px;
  background-color: #369e90;
}

.product-box__bg img {
  height: 225px;
  width: 100%;
  object-fit: cover;
}

.product-box__bg {
  background-size: cover;
  border-radius: 3px 3px 0 0;
  background-position: center;
}

.product-box__title {
  font-size: 18px;
  margin-bottom: 0;
}

.product-box__content {
  padding: 15px;
}

.product-price {
  margin-bottom: 0;
  font-size: 16px;
}

.readmore-btn {
  display: inline-block;
  text-decoration: underline !important;
  color: #28beef;
  font-size: 18px;
  font-weight: 600;
}

.readmore-btn:hover {
  color: #369e90;
}

#huurwoningen {
  background-size: cover;
  background-position: center;
  background-image: url("assets/images/CTWonen_Picture.jpg");
}

.huurwoningen-content__box {
  padding: 50px 100px;
  background: #fff;
  text-align: center;
}

#huren {
  background: #369e90;
}

/*Icon Card*/

.icon-card {
  text-align: center;
  margin-top: 30px;
}

.icon-card__content h4 {
  color: #fff;
}

.icon-card__icon {
  height: 100px;
  width: 100px;
  background: #fff;
  border-radius: 50px !important;
  font-size: 50px;
  color: #28beef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 25px;
}

.icon-card__content {
  color: #fff;
}

.icon-card__content--dark,
.icon-card__content--dark h4 {
  color: #000002;
}

.keppel_color {
  background: #369e90 !important;
  color: #fff;
}
.icon-card__dark {
  background: #fff;
}

.icon-card__dark .icon-card__content h4,
.icon-card__dark .icon-card__content {
  color: #000002;
}

/*Left Align Icon Card*/

.icon-card--left {
  text-align: left;
}

.icon-card--left .icon-card__icon {
  margin-left: 0;
}

.icon-card__icon--sm {
  width: 70px;
  height: 70px;
  font-size: 40px;
}

.icon-card--box {
  padding: 30px 40px;
  border-radius: 5px;
}

#huren.huren--light {
  background: #fff;
}
.huren--light .icon-card__content,
.huren--light .icon-card__content h4 {
  color: #231f20;
}

.huren--light .icon-card__icon {
  background: #28beef;
  color: #fff;
}

.search-tags__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.search-tags__wrap .search-btn {
  margin-left: 0;
  margin-right: 10px;
}

.search-tags__wrap .search-btn a {
  background: #f6f7f7;
  color: #000002;
}

/*Mixitup*/

#MixiTup {
  overflow: hidden;
}
#MixiTup .mix {
  display: none;
}

.mixitup-control {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.mixitup-control li {
  cursor: pointer;
  background: #f6f7f7;
  padding: 10px 20px;
  border-radius: 50px;
  margin: 5px;
  min-width: 100px;
  text-align: center;
  color: #000002;
  font-weight: 700;
  transition: 0.3s;
}

.mixitup-control li.active,
.mixitup-control li:hover {
  background: #28beef;
  color: #fff;
}

.mix-item_content h4 {
  font-size: 18px;
  margin-top: 15px;
  margin-bottom: 0;
}

.mix-single-item {
  margin-top: 25px;
}

.mix-item_bg,
.mix-item_bg img {
  border-radius: 5px;
}

#cta {
  padding: 120px 0;
  background-size: cover;
  background-position: center;
  background-image: url("assets/images/cta-bg.jpg");
}
form.form_inline {
  display: flex;
}

form.form_inline button[type="submit"] {
  width: auto;
  margin-left: 10px;
  border-radius: 3px;
  background: #28beef;
  color: #fff;
  text-transform: uppercase;
  border-color: #28beef;
}

.icon-card--2 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.icon-card--2 .icon-card__content,
.icon-card--2 .icon-card__content h4 {
  color: #231f20;
}

.icon-card__icon--2 {
  background-color: #28beef;
  color: #fff;
}

footer#footer {
  background: #322f31;
}

footer,
footer a {
  color: #fff;
  font-size: 14px;
}

ul.footer-menu {
  list-style: none;
}

.footer-top {
  padding: 50px 0;
}

.footer-bottom {
  padding: 15px 0;
}

.copyright {
  padding: 10px 0;
}

.social-items a {
  font-size: 20px !important;
  display: inline-block;
  line-height: 1;
  margin-top: 10px;
  padding-bottom: 10px;
}

.social-items {
  text-align: right;
}

.social-items a:not(:last-child) {
  margin-right: 15px;
}

.over-ons_hero {
  background-position: center top;
  background-image: url(assets/images/over-ons_hero--bg.jpg);
}

/*Our Team*/
#our-team {
  background: #f7f7f8;
}

.team-member {
  position: relative;
  overflow: hidden;
}

.team-member__info {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  z-index: 1;
  padding: 20px;
  width: 100%;
}

.team-member__info > h5 {
  width: 140px;
}

.team-member__info:after {
  content: "";
  width: 240px;
  height: 200px;
  background: #369e90;
  position: absolute;
  bottom: -90px;
  left: -45px;
  z-index: -1;
  border-radius: 50%;
  transition: 0.3s;
}

.team-member:hover .team-member__info:after {
  background: #28beef;
}

.aq-bg--shape {
  position: relative;
  z-index: 1;
}

.aq-bg--shape:after {
  content: "";
  height: 60%;
  width: 100%;
  left: 0;
  top: 0;
  position: absolute;
  background: #369e90;
  z-index: -1;
}

.aq-bg--shape.skyblue-bg:after {
  background: #28beef;
}

.aq-bg--shape .icon-card {
  background: #fff;
}

.dark-color {
  color: #231f20;
}

.contact_hero {
  background-image: url("assets/images/contact-bg.jpg");
}

#page-banner {
  background-color: #369e90;
  padding-top: 75px;
  padding-bottom: 50px;
}

.ctw-button-wrap {
  display: flex;
  justify-content: space-between;
}

.search-form-2 .search-filter-wrap form div:not(:last-child):after {
  display: none;
}

.search-form-2 .search-filter-wrap form {
  display: block;
}

button.search-btn {
  border: none;
  background: transparent;
}

button.search-btn a {
  background-color: #28beef;
}

button.search-btn a:hover {
  background-color: #000002;
}

.search-keyword-input input {
  margin-bottom: 0;
  font-size: 20px;
  font-weight: 700;
  border: none;
  padding: 0;
}

.search-keyword-input input::placeholder {
  color: rgba(black, 0.6);
}

.form-widget select {
  width: 100%;
  border: 1px solid #ddd;
  padding: 10px 20px 10px 10px;
  background-position: center right 10px;
}

.form-widget select:not(:last-child) {
  margin-bottom: 15px;
}

.tag-menu {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}

.tag-menu li {
  margin: 5px 5px;
}

.tag-menu a {
  display: block;
  padding: 7px 15px;
  background: #f6f7f7;
  border-radius: 50px;
  color: #000002;
}

.tag-menu a:hover,
.tag-menu li.active a {
  background: #28beef;
  color: #fff;
}

.sidebar-widget:not(:last-child) {
  margin-bottom: 30px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 30px;
}

.custom-control {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #28beef;
  background-color: #28beef;
}

.count-number {
  color: #b7b8bb;
}

.load-btn {
  display: inline-block;
  margin-top: 10px;
  color: #369e90;
}

.product-tags-search {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.product-box__bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.product-tags-search li {
  position: relative;
  padding-right: 20px;
}

.product-tags-search li:after {
  content: "\f111";
  font-family: fontawesome;
  font-size: 5px;
  color: #28beef;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
}

.product-box__list .product-box__bg img {
  opacity: 1;
  visibility: visible;
  border-radius: 3px;
}

.product-box__list .badge {
  left: 15px !important;
  right: auto !important;
}

.product-box__list:not(:last-child) {
  margin-bottom: 30px;
}

.product-box__title--2,
.product-box__title--2 a {
  color: #369e90;
}

.product-box__title--2:hover,
.product-box__title--2 a:hover {
  color: #28beef;
}

.pagination {
  position: relative;
}

.pagination li a {
  display: inline-flex;
  background: #28beef;
  border-radius: 50px;
  min-width: 40px;
  min-height: 40px;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 5px;
}

.pagination li {
  display: inline-flex;
  align-items: center;
}

.pagination li:not(:last-child) {
  margin-right: 5px;
}

.pagination li a:hover {
  background: #369e90;
}

.pagination .next-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.pagination .next-btn a {
  min-width: 100px;
}

.button-link a {
  width: 50px;
  height: 50px;
  display: inline-flex;
  background: #28beef;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  color: #fff;
}

.button-link a:hover {
  background: #369e90;
}

.button-link {
  display: flex;
}

.ctw-button-wrap {
  align-items: center;
  flex-wrap: wrap;
}

.search-filter-wrap button[type="submit"] {
  max-width: 125px;
  min-width: 125px;
}

.image-card {
  margin-top: 20px;
}

.widget-list {
  list-style: none;
}

.widget-list li a {
  color: #000002;
}

.widget-list li {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.widget-list li span {
  color: #b7b8bb;
}
.widget-list li a:hover {
  color: #369e90;
}

.verhuren_hero {
  background-image: url("assets/images/verhuren_hero--bg.jpg");
}

#verhuren-step {
  background: #28beef;
}

#pricing-table {
  background: #f7f7f8;
  background-size: cover;
}

.pt__progress {
  min-height: 180px;
}

.pt__progress p {
  font-weight: 600;
  line-height: 1.3;
}

.pt__plan {
  min-height: 85px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card.pt-card {
  padding: 25px;
}

.pt-switch-btn {
  border: 2px solid #bec3c7;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background: #dbdee0;
  margin-top: 30px;
  margin-bottom: 30px;
}

.pt-switch-header h6 {
  margin-bottom: 0;
}

.pt-switch-header h6 strong {
  color: #28beef;
}

.pt-switch-header a {
  text-decoration: underline;
  font-size: 14px;
}

.custom-switch label {
  text-indent: -15000px;
}

.pt-price-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 120px;
}

.pt-price-amount h4 {
  font-size: 18px;
  margin-bottom: 0;
}

.pt-price-amount {
  text-align: right;
}

.pt__progress .progress {
  margin-bottom: 15px;
  border-radius: 50px;
}

.pt__progress .progress-bar {
  border-radius: 50px;
  background: #28beef;
}

.pt__plan span {
  color: #369e90;
}

.pt-list-item li {
  position: relative;
  padding-left: 25px;
}

.pt-list-item li:not(:last-child) {
  margin-bottom: 7px;
}

.pt-list-item li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0;
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  color: #28beef;
}

.sub-btn a {
  display: block;
  padding: 10px 20px;
  background: #369e90;
  border-radius: 50px;
  color: #fff;
  font-weight: 600;
}

.sub-btn a:hover {
  background: #28beef;
}

.pt-price-btn {
  text-align: center;
}

.link-btn {
  text-decoration: underline;
  color: #28beef;
}

.pt-price-btn .sub-btn {
  margin: 10px 0;
}
.custom-switch .custom-control-label::before {
  width: 40px;
  border-radius: 50px;
  height: 20px;
}
.custom-switch .custom-control-label::after {
  width: calc(20px - 4px);
  height: calc(20px - 4px);
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(20px);
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.pt-card strong {
  color: #28beef;
}

.partner-logo-slide {
  min-height: 125px;
  max-height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #e2e2e2;
  padding: 10px;
}

#partner-logo-slider .owl-nav > div {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 60px;
  width: 15px;
  background: rgba(40, 190, 239, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

#partner-logo-slider .owl-nav > div.owl-prev {
  left: 0;
  border-radius: 0 50px 50px 0;
}

#partner-logo-slider .owl-nav > div.owl-next {
  right: 0;
  border-radius: 50px 0 0 50px;
}

.owl-carousel .owl-item img {
  width: auto;
}

.quotes-title {
  font-weight: 300;
}

.quotes-content {
  position: relative;
  margin: 15px 0;
}

.quotes-content:before {
  content: "\f10d";
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  font-size: 30px;
  color: #28beef;
}

.skyblue__layout .secondary-menu li:hover > a,
.skyblue__layout .secondary-menu li.active a,
.skyblue__layout .main_menu ul li.active a,
.skyblue__layout .main_menu ul li:hover > a {
  color: #28beef;
}

.skyblue__layout .ellipse_shape--skyblue:before,
.skyblue__layout .ellipse_shape--skyblue:after {
  background: #28beef;
}

.skyblue__layout .home_hero {
  background-color: #28beef;
}

.pakketten_hero {
  background-image: url("assets/images/cta-bg.jpg");
}

.pt-switch-btn__wrap .pt-switch-btn {
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 5px 5px 0 0;
  border-left: 0;
  border-top: 0;
  border-right: 0;
}

.pt-switch-btn__wrap {
  border: 2px solid #bec3c7;
  border-radius: 10px;
}

.pt-switch-btn {
  display: flex;
}

.single-switch {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.single-switch h6 {
  margin-bottom: 0;
}

.single-switch .custom-control {
  align-items: flex-start;
}
.single-switch span {
  max-width: 70%;
}
.pt-switch-footer {
  padding: 10px 20px;
}

.pt-price-box--2 {
  align-items: flex-start;
  margin-top: 15px;
  min-height: auto;
  margin-bottom: 15px;
}

.pt-price-box--2 small {
  display: block;
}

.voo__hero {
  background-image: url("assets/images/over-ons_hero--bg--2.jpg");
}

#circle-cta {
  background-image: url("assets/images/circle-bg.jpg");
  padding: 150px 0;
  background-position: center;
  background-size: cover;
}
.circle-box {
  background: #28beef;
  height: 350px;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.ellipse__right .ellipse_shape {
  left: auto;
  right: 0;
  width: 35%;
}

.ellipse__right .ellipse_shape:after {
  left: auto;
  clip-path: ellipse(50% 55% at 120% 50%);
  right: 100%;
}

.cta-content {
  text-align: right;
}

#cta.verhuren-contact__cta {
  background-image: url("assets/images/verhuren-contact__cta.jpg");
}

.beheer__hero {
  background-image: url("assets/images/verhuren-contact__cta.jpg");
}

.beheer__hero .ellipse_shape {
  width: 30%;
}

.icon-card__left {
  display: flex;
  text-align: left;
  margin-top: 0;
}

.icon-card__left .icon-card__icon {
  height: auto;
  width: auto;
  margin-bottom: 0;
  align-items: flex-start;
  margin-right: 15px;
  font-size: 30px;
  background: transparent;
}

.icon-box {
  width: 150px;
  height: 150px;
  background: #369e90;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  border-radius: 50%;
  color: #fff;
}

.icon-box--light {
  background: #fff;
  color: #369e90;
}

.spacing--big {
  padding-top: 50px;
  padding-bottom: 50px;
}

.skyblue--bg {
  background-color: #28beef !important;
}

.ellipse_icons--shape {
  position: relative;
  z-index: 1;
}

.ellipse_icons {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.ellipse_icons img {
  position: absolute;
  right: 100px;
  top: 50px;
}

.ellipse_icons-1 img {
  right: 100px;
  top: 50px;
}

.ellipse_icons--2 img {
  left: 0;
  top: 40px;
  right: auto;
}

.ellipse_icons--2 img:nth-child(2) {
  left: auto;
  right: 0;
}

.ellipse_icons--2 img:nth-child(3) {
  top: auto;
  bottom: 30px;
  left: 70%;
  transform: translatex(-30%);
}

.ellipse_icons-3 img {
  top: 0;
  right: 175px;
}

.ellipse_icons-3 img:nth-child(2) {
  top: auto;
  right: 45%;
  bottom: 30px;
  transform: translateX(50%);
}

.section-header a {
  color: #231f20;
}

@import url(./assets/css/responsive.css);
@import url(./assets/css/all.min.css);
@import url(./assets/css/animate.min.css);
@import url(./assets/css/bootstrap-v4.3.1.min.css);
@import url(./assets/css/magnific-popup.css);
@import url(./assets/css/meanmenu.min.css);
@import url(./assets/css/navigation.css);
@import url(./assets/css/owl.carousel.min.css);

/* Medium Layout: 1280px. */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
}

/* Tablet Layout: 768px. */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .search-filter-wrap form div:not(:last-child):after {
    content: none;
  }

  .search-filter-wrap select.select_menu {
    font-size: 18px;
  }

  .card-deck {
    flex-direction: column;
  }

  .card-deck .card {
    margin-bottom: 30px;
  }
}

/* Mobile Layout: 320px. */
@media only screen and (max-width: 767px) {
  .icon-box {
    width: 75px;
    height: 75px;
    font-size: 30px;
  }

  .search-filter-wrap > form > div {
    width: 100%;
  }

  .search-filter-wrap form div:not(:last-child):after {
    content: none;
  }

  .search-filter-wrap select.select_menu {
    font-size: 18px;
  }

  .ellipse_shape:after {
    display: none;
  }

  .ellipse_shape {
    width: 100%;
  }

  .ellipse_shape:before {
    opacity: 0.8;
  }

  .huurwoningen-content__box {
    padding: 30px 15px;
  }

  .product-box__content {
    padding: 15px 0;
  }

  .product-box__title--2,
  .product-box__title--2 a {
    font-size: 24px;
  }

  .signle-search-card {
    margin-top: 20px;
  }

  .card-deck {
    flex-direction: column;
    align-items: center;
  }

  .card-deck .card {
    margin-bottom: 30px;
  }
}

.huren {
  position: relative;
}

.city-dropdown {
  position: absolute;
  background-color: #f7f7f8;
  left: -32px;
  z-index: 1000;
  text-align: start;
  padding: 0 32px 16px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.5);
}

.city-dropdown a {
  color: black !important;
}

.city-dropdown a:hover {
  color: #369e90 !important;
}

/* Wide Mobile Layout: 480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .logo {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .navigation {
    display: initial;
  }
  .logo.mobile_off {
    display: none;
  }
  .mobile-menu.dextop_off {
    display: block;
  }
  .mobile-menu .logo {
    position: absolute;
    left: 15px;
    top: 65px;
    z-index: 9;
    max-width: 200px;
  }

  .mobile-menu .logo img {
    max-width: 150px;
    margin-right: 0;
  }

  header {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9;
    width: 100%;
    background: #fff;
  }
  .mean-container .mean-bar {
    float: left;
    width: 100%;
    position: relative;
    background: #fff;
    padding: 0;
    min-height: 42px;
    z-index: 9;
  }
  .mean-container .mean-nav {
    margin-top: 55px;
  }
  .mean-container a.meanmenu-reveal {
    color: #333;
    right: 10px !important;
    top: 5px;
  }
  .mean-container a.meanmenu-reveal span {
    background-color: #333;
  }

  .header-top {
    padding: 10px 0;
  }

  .secondary-menu li:first-child {
    margin-left: 0;
  }

  #hero,
  #page-banner {
    margin-top: 115px;
  }

  .search-filter-wrap form div:not(:last-child):after {
    right: -20px;
  }

  .main_menu ul li a {
    padding: 15px 10px;
    font-size: 13px;
    white-space: nowrap;
  }
}

.listitems li:before {
  content: "✓    ";
}

.listitem-root {
  display: flex;
  justify-content: center;
}

.listitems {
  list-style: none;
  text-align: start;
}

@media only screen and (min-width: 1024px) {
  .row.huren--light__border > div:not(:last-child) {
    border-right: 1px solid #d8d9db;
  }

  .logo {
    display: initial;
  }

  .form-row > div:not(:last-child) {
    border-right: 1px solid #d8d9db;
  }

  .main_menu ul li a {
    padding: 15px 25px;
    font-size: 18px;
    white-space: nowrap;
  }

  .form-row .ctw-button-wrap {
    border-left: 0 solid #d8d9db;
    padding-left: 0 !important;
  }
}

@media only screen and (min-width: 720px) {
  .ctw-button-wrap {
    border-left: 1px solid #d8d9db;
    padding-left: 20px !important;
  }
}
