/*
 Navigation
 =============== */
.navigation {
  display: flex;
  align-items: center;
}
.mobile-menu.dextop_off {
  display: none;
}
.logo {
  margin: 0 20px 0 0;
}
.main_menu ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-content: center;
}
.main_menu ul li {
  position: relative;
}
.main_menu ul li a {
  display: block;
  color: #000002;
}

/*Dropdown Menu*/
.main_menu ul ul {
  position: absolute;
  top: 110%;
  left: 0;
  background: #fff;
  transition: 0.4s;
  min-width: 220px;
  opacity: 0;
  visibility: hidden;
  z-index: 2;
  display: inherit;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.main_menu ul li:hover > ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.main_menu ul ul li {
  display: block;
}
.main_menu ul ul li a {
  padding: 5px 15px;
}

/*Submenu Level 2*/
.main_menu ul ul ul {
  left: 100%;
  opacity: 0;
  visibility: hidden;
}
.main_menu ul ul li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 0;
}

.sticky {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  background: #fff;
  box-shadow: 0 1px 3px rgba(50, 50, 50, 0.4);
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 3px 7px rgba(25, 25, 25, 0.2);
  z-index: 9;
}
.mobile-menu {
  margin-left: -15px;
  margin-right: -15px;
}
